(function () {
	'use strict';
	angular
		.module('app')
		.controller('SharesCtrl', [
			'$scope',
			'$translate',
			'$timeout',
			'seedcodeCalendar',
			'shares',
			'utilities',
			'environment',
			'hash',
			'manageFilters',
			SharesCtrl,
		])
		.controller('SharesPreviewCtrl', [
			'$scope',
			'shares',
			'utilities',
			'seedcodeCalendar',
			SharesPreviewCtrl,
		]);

	function SharesCtrl(
		$scope,
		$translate,
		$timeout,
		seedcodeCalendar,
		shares,
		utilities,
		environment,
		hash,
		manageFilters
	) {
		var preview;
		var previewElement;

		// Get plan level
		var hashData = hash.get();
		var lockExpiration = hashData.planLevel === 0 ? true : false;
		$scope.lockExpiration = lockExpiration;

		//Load config from our model and watch for future updates
		$scope.userId = seedcodeCalendar.get('config').userID;
		$scope.share.transition = true;
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		$scope.calendar.view = seedcodeCalendar.get('view');
		$scope.calendar.days = $scope.calendar.view.end.diff(
			$scope.calendar.view.start,
			'days'
		);
		$scope.calendar.weeks = $scope.calendar.view.end.diff(
			$scope.calendar.view.start,
			'weeks'
		);
		$scope.calendar.months = $scope.calendar.view.end.diff(
			$scope.calendar.view.start,
			'months'
		);

		if ($scope.calendar.view.name.indexOf('Day') !== -1) {
			$scope.calendar.duration = 'Days';
		} else if ($scope.calendar.view.name.indexOf('Week') !== -1) {
			$scope.calendar.duration = 'Weeks';
		} else if ($scope.calendar.view.name.indexOf('month') !== -1) {
			$scope.calendar.duration = 'Months';
		} else if ($scope.calendar.view.name.indexOf('Horizon') !== -1) {
			$scope.calendar.duration = 'Weeks';
		} else if ($scope.calendar.view.name.indexOf('Resource') !== -1) {
			$scope.calendar.duration = 'Days';
		}

		//Load timezone list
		$scope.calendar.timezones = utilities.getTimezones();

		if (!$scope.share) {
			$scope.share = {};
		}

		//Set initial state if sharing and bookmarks are Allowed
		isShareEnabled();

		//Set default share setting tab
		$scope.share.settingsTab = $scope.share.create ? 'options' : 'url';

		//Set current platform
		$scope.share.platform = utilities.getDBKPlatform();
		$scope.isPhone = environment.isPhone;

		$scope.edit = {};

		$scope.changeTab = function (name) {
			$scope.share.settingsTab = name;
		};

		shares.shareAuth(function (result) {
			if (!result) {
				$scope.$evalAsync(function () {
					$scope.share.authorized = 'error';
				});
				return;
			} else if (result === 'trial' || result === 'subscription') {
				$scope.$evalAsync(function () {
					$scope.share.authorized = result;
				});
				return;
			}
			//Check if we are creating
			if ($scope.share.create) {
				addShare();
			}
			//Go directly to details if share passed
			else if ($scope.share.id) {
				$scope.share.transition = false;
				processShare($scope.share);
				shareDetails($scope.share);
			}

			//Initialize list of shares for this user
			getShareList();

			$scope.$evalAsync(function () {
				$scope.share.authorized = 'success';
			});
		});

		$scope.toggleShowInTimezone = toggleShowInTimezone;
		$scope.toggleConfirmDelete = toggleConfirmDelete;
		$scope.toggleConfirmUpdate = toggleConfirmUpdate;
		$scope.toggleConfirmPrivateUpdate = toggleConfirmPrivateUpdate;
		$scope.toggleConfirmSwitchDate = toggleConfirmSwitchDate;
		$scope.addShare = addShare;
		$scope.createShare = createShare;
		$scope.updatePrivateShare = updatePrivateShare;
		$scope.updateShareExpiration = updateShareExpiration;
		$scope.updateShareTimezone = updateShareTimezone;
		$scope.updateDefaultDatePreference = updateDefaultDatePreference;
		$scope.updateShareWith = updateShareWith;
		$scope.updateShareName = updateShareName;
		$scope.updateSwitchDate = updateSwitchDate;
		$scope.updateShareSEO = updateShareSEO;
		$scope.updateShare = updateShare;
		$scope.close = close;
		$scope.shareDetails = shareDetails;
		$scope.removeShare = removeShare;
		$scope.gotoShareURL = gotoShareURL;
		$scope.isPublic = isPublic;
		$scope.toggleFavorite = toggleFavorite;
		$scope.previewShare = previewShare;
		$scope.destroyPreview = destroyPreview;
		$scope.updateURLDisplay = updateURLDisplay;
		$scope.applyfilter = applyFilter;

		$scope.defaultDateOptions = [
			{id: 'selectedDate', label: 'The date presently in view'},
			{id: 'currentDate', label: 'The current date'},
			{id: 'firstOfWeek', label: 'The first day of the current week'},
			{id: 'firstOfMonth', label: 'The first day of the current month'},
			{
				id: 'firstOfQuarter',
				label: 'The first day of the current quarter',
			},
			{id: 'firstOfYear', label: 'The first day of the current year'},
		];

		$scope.stringToURLSlug = utilities.stringToURLSlug;

		$scope.help = function (page, pagesf, fullURL, pageShare) {
			utilities.help(page, pagesf, fullURL, pageShare);
		};

		$scope.copySuccess = function (e) {
			var translations = $translate.instant(['Copied to clipboard']);
			// console.info('Action:', e.action);
			// console.info('Text:', e.text);
			// console.info('Trigger:', e.trigger);
			e.clearSelection();
			utilities.showMessage(translations['Copied to clipboard']);
		};

		$scope.copyError = function (e) {
			var translations = $translate.instant([
				'Please copy the selected text',
			]);
			// console.error('Action:', e.action);
			// console.error('Trigger:', e.trigger);
			utilities.showMessage(
				translations.instant['Please copy the selected text']
			);
		};

		function getShareList() {
			var getUser = seedcodeCalendar.get('userPromises').getUser();
			$scope.loading = true;

			getUser.then(function (result) {
				$scope.userId = result ? result.id : null;
				shares.getUserShareList(function (result) {
					var platform = utilities.getDBKPlatform();
					var shareList = [];
					for (var property in result) {
						if (
							utilities.basePlatformMatch(
								platform,
								result[property].platform
							)
						) {
							shareList.push(processShare(result[property]));
						}
					}
					shareList.sort(favoriteSort);

					$scope.$evalAsync(function () {
						$scope.loading = false;
						$scope.share.list = shareList;
						applyFilter();
					});
				});
			});

			function favoriteSort(a, b) {
				var aFavoriteCreated = a.favorite[$scope.userId]
					? a.favorite[$scope.userId].created
					: null;
				var bFavoriteCreated = b.favorite[$scope.userId]
					? b.favorite[$scope.userId].created
					: null;
				if (aFavoriteCreated && bFavoriteCreated) {
					if (aFavoriteCreated > bFavoriteCreated) {
						return -1;
					} else {
						return 1;
					}
				} else if (aFavoriteCreated && !bFavoriteCreated) {
					return -1;
				} else if (bFavoriteCreated && !aFavoriteCreated) {
					return 1;
				} else {
					if (a.created > b.created) {
						return -1;
					} else {
						return 1;
					}
				}
			}
		}

		function gotoShareURL($event, share) {
			if (isPublic(share.shareWith)) {
				utilities.help(share.url, share.url, true);
			} else {
				close($event);
				shares.update(
					share.id,
					function (updatedShare, error) {
						if (!updatedShare && error) {
							utilities.showModal(
								error.title || 'Unable to visit share',
								error.content,
								error.cancelButtonText || 'ok'
							);
						}
					},
					null,
					true
				);
			}
		}

		function toggleShowInTimezone(editObj) {
			if (editObj.showInTimezone) {
				editObj.timezone = moment.tz.guess();
			} else {
				editObj.timezone = null;
			}
		}

		function toggleConfirmDelete() {
			$scope.confirmDelete = !$scope.confirmDelete;
		}

		function toggleConfirmSwitchDate() {
			$scope.confirmSwitchDate = !$scope.confirmSwitchDate;
		}

		function toggleConfirmUpdate() {
			$scope.confirmUpdate = !$scope.confirmUpdate;
			if (!$scope.confirmUpdate) {
				$scope.edit.shareWith = $scope.edit.share.shareWith;
			}
		}

		function toggleConfirmPrivateUpdate() {
			$scope.confirmPrivateUpdate = !$scope.confirmPrivateUpdate;
			if (!$scope.confirmPrivateUpdate) {
				$scope.edit.shareWith = $scope.edit.share.shareWith;
			}
		}

		function addShare() {
			$scope.edit = {expires: lockExpiration ? 3 : 90}; // If our plan is locking expiration default to 3 days instead of 90
			$scope.edit.created = null;
			$scope.edit.detailType = 'add';
			$scope.share.secondPanel = true;
			$scope.edit.defaultDatePreference = 'currentDate';
			$scope.edit.extend = 0;
			$scope.edit.currentExtend = 0;
			$scope.edit.shareWith = 'self';
			$scope.edit.embedURL = false;
			$scope.edit.copyURLText = 'Copy URL';
			$scope.getTargetDate();
		}

		function createShare() {
			// Don't allow share creation if user doesn't have access
			if (!isShareEnabled() && $scope.edit.shareWith !== 'self') {
				return;
			}

			var name = $scope.edit.name;
			var urlSlug = $scope.edit.urlSlug;
			//var extend = ($scope.edit.extend==='true') ? true : false;
			var expiresDays = parseInt($scope.edit.expires, 10);
			var translations = $translate.instant(['Creating share']);
			$scope.edit.urlSlug = utilities.stringToURLSlug(
				$scope.edit.urlSlug
			);
			$scope.edit.creating = true;

			//Check if url slug is already in use.
			shares.getBookmark(urlSlug, function (result) {
				if (!result) {
					completeShareCreation();
				} else {
					//URL slug exists
					$scope.$evalAsync(function () {
						$scope.edit.slugExists = true;
					});
				}
			});

			function completeShareCreation() {
				progressCallback(0, translations['Creating share'], true, null);
				shares.create(
					name,
					expiresDays,
					shareSuccess,
					progressCallback,
					$scope.edit.extend,
					$scope.calendar.duration,
					$scope.edit.showInTimezone,
					$scope.edit.timezone,
					$scope.edit.shareWith,
					$scope.edit.switchDate,
					$scope.edit
				);
			}
		}

		function updatePrivateShare(share, callback) {
			var translations = $translate.instant([
				'Updating bookmark',
				'Bookmark updated successfully',
			]);
			$scope.edit.updating = true;
			progressCallback(0, translations['Updating bookmark'], true, null);

			toggleConfirmPrivateUpdate();
			shares.create(
				null,
				null,
				updateSuccess,
				null,
				null,
				null,
				null,
				null,
				null,
				null,
				share
			);

			function updateSuccess(shareData) {
				progressCallback(
					1,
					translations['Updating bookmark'],
					true,
					null
				);
				progressCallback(
					0,
					translations['Bookmark updated successfully'],
					null,
					true,
					null,
					function () {
						// Run callback if one was supplied (assumed to handle updating status if a callback is used)
						if (callback) {
							callback();
						} else {
							// Set updating to false
							$scope.edit.updating = false;
						}
					}
				);
			}
		}

		function processShare(share) {
			var expires = moment(share.expires);
			var now = moment();
			if (expires.isBefore(now)) {
				share.isExpired = true;
			} else {
				share.isExpired = false;
			}

			var days = expires.diff(now, 'days');

			share.expiresHuman = days
				? moment(share.expires).add(1, 'days').fromNow()
				: moment(share.expires).fromNow();
			share.deletionDays = Math.max(days + 30, 0);
			share.expiresDate = moment(share.expires).format('LL');
			share.createdDate = moment(share.created).format('LL');
			share.dateRange =
				moment(share.start).format('ll') +
				' - ' +
				moment(share.end).format('ll');
			share.url = shares.getShareURL(share.id, isPublic(share.shareWith));
			share.shareWithHuman =
				share.shareWith === 'self'
					? 'Just Me'
					: share.shareWith === 'group'
						? 'My DayBack Group'
						: 'Public';
			share.favorite = share.favorite || {};
			share.favoriteSelected = !!share.favorite[$scope.userId];
			share.showInTimezone = !!share.timezone;
			return share;
		}

		$scope.getTargetDate = function (event) {
			var end;

			$scope.edit.extend =
				$scope.edit.extend < 0 ? 0 : $scope.edit.extend;

			var maxDate = moment($scope.calendar.view.intervalStart)
				.add(24, 'month')
				.startOf('week')
				.add(41, 'days');

			if ($scope.calendar.view.name === 'month') {
				end = moment($scope.calendar.view.intervalStart)
					.add($scope.edit.extend, 'month')
					.startOf('week')
					.add(41, 'days');
			} else {
				end = moment($scope.calendar.view.end)
					.add(
						$scope.edit.extend,
						$scope.calendar.duration.toLowerCase()
					)
					.subtract(1, 'seconds');
			}

			if (
				$scope.calendar.view.name !== 'basicHorizon' &&
				end.isAfter(maxDate)
			) {
				$scope.edit.extend = $scope.edit.currentExtend;
				utilities.showMessage(
					'Share cannot end after ' + maxDate.format('LL'),
					0,
					5000
				);
				return false;
			} else {
				$scope.edit.currentExtend = $scope.edit.extend;
			}

			$scope.edit.targetDate =
				$scope.calendar.duration +
				' to ' +
				end.format('LL') +
				' (2 Years Max)';
		};

		function createShareDetail(share) {
			$scope.edit = {};
			$scope.edit.share = share;
			$scope.share.settingsTab = 'url';
			//set editable expires in field
			var today = moment(moment().format('YYYY-MM-DD'));
			var expiresDays = moment($scope.edit.share.expiresDate).diff(
				today,
				'days'
			);
			$scope.edit.expires = expiresDays;
			$scope.edit.shareWith = share.shareWith || 'public';
			$scope.edit.name = share.name;
			$scope.edit.urlDisplay = share.url;
			$scope.edit.embedURL = false;
			$scope.edit.copyURLText = 'Copy URL';

			$scope.edit.seoTitle = share.seoTitle;
			$scope.edit.seoDescription = share.seoDescription;
			$scope.edit.seoImageURL = share.seoImageURL;

			if (
				!$scope.edit.share.defaultDatePreference &&
				!isPublic($scope.edit.shareWith)
			) {
				$scope.edit.share.defaultDatePreference = $scope.edit.share
					.switchDate
					? 'selectedDate'
					: 'currentDate';
			}
		}

		function updateShareExpiration() {
			$scope.edit.expires =
				$scope.edit.expires > 730 ? 730 : $scope.edit.expires;
			var today = moment(moment().format('YYYY-MM-DD'));
			$scope.edit.share.expiresDate = today
				.add('days', $scope.edit.expires)
				.format('LL');
			$scope.edit.share.expires = moment(
				$scope.edit.share.expiresDate
			).valueOf();
			shares.updateShareProperty(
				$scope.edit.share,
				'expires',
				$scope.edit.share.expires,
				callback
			);
			function callback(data) {
				$scope.edit.share.expiresDate = data
					? moment(data).format('LL')
					: $scope.edit.share.expiresDate;
				$scope.edit.share.expires = data
					? data
					: $scope.edit.share.expires;
				processShare($scope.edit.share);
			}
		}

		function updateShareTimezone() {
			shares.updateShareTimezone(
				$scope.edit.share,
				$scope.edit.share.showInTimezone,
				$scope.edit.share.timezone,
				callback
			);
			function callback(data) {
				processShare($scope.edit.share);
			}
		}

		function updateDefaultDatePreference() {
			const config = seedcodeCalendar.get('config');

			if ($scope.edit.share.defaultDatePreference === 'selectedDate') {
				$scope.edit.share.defaultDate = config.defaultDate;
			} else {
				$scope.edit.share.defaultDate = null;
			}

			shares.updateShareProperty(
				$scope.edit.share,
				'defaultDatePreference',
				$scope.edit.share.defaultDatePreference,
				updateSetting
			);

			function updateSetting() {
				shares.updateSettingsData(
					$scope.edit.share,
					'defaultDate',
					config.defaultDate,
					() => {
						processShare($scope.edit.share);
					}
				);
			}
		}

		function updateShareSEO(property, value) {
			shares.updateShareSEO($scope.edit.share, property, value, callback);
			function callback(data) {
				processShare($scope.edit.share);
			}
		}

		function updateSwitchDate(share, confirmed) {
			var config = seedcodeCalendar.get('config');
			var isPublicShare = isPublic($scope.edit.shareWith);

			if (isPublicShare && share.switchDate && !confirmed) {
				toggleConfirmSwitchDate();
			} else {
				// If this was confirmed from a dialog we need to hide the dialog
				if (confirmed) {
					toggleConfirmSwitchDate();
				}
				shares.updateSwitchDate(
					$scope.edit.share,
					$scope.edit.share.switchDate,
					config.defaultDate,
					callback
				);
			}

			function callback(data) {
				if (isPublicShare && share.switchDate) {
					updateShare(share, true);
				}
			}
		}

		function updateURLDisplay(share, embed) {
			$scope.edit.urlDisplay = shares.getShareURL(
				share.id,
				isPublic(share.shareWith),
				embed
			);
			$scope.edit.embedURL = embed;
			$scope.edit.copyURLText = embed ? 'Copy Embed Code' : 'Copy URL';
		}

		function updateShareWith(confirmed, callback) {
			const publicShare = isPublic($scope.edit.shareWith);
			if (publicShare && !confirmed) {
				toggleConfirmUpdate();
			} else {
				shares.updateShareProperty(
					$scope.edit.share,
					'shareWith',
					$scope.edit.shareWith,
					applyShareWith
				);
			}

			function applyShareWith(data) {
				$scope.edit.share.shareWith = data
					? data.shareWith
					: $scope.edit.share.shareWith;
				if (
					$scope.edit.share.switchDate &&
					!$scope.edit.share.defaultDatePreference
				) {
					$scope.edit.share.defaultDatePreference = 'selectedDate';
					shares.updateShareProperty(
						$scope.edit.share,
						'defaultDatePreference',
						$scope.edit.share.defaultDatePreference,
						processData
					);
				} else {
					processData();
				}
			}

			function processData() {
				processShare($scope.edit.share);
				updateURLDisplay($scope.edit.share, $scope.edit.embedURL);
				if (callback) {
					callback($scope.edit.share.shareWith);
				}
			}
		}

		function updateShareName() {
			shares.updateShareProperty(
				$scope.edit.share,
				'name',
				$scope.edit.name,
				callback
			);
			function callback(data) {
				$scope.edit.share.name = data ? data : $scope.edit.share.name;
				processShare($scope.edit.share);
			}
		}

		function toggleFavorite(bookmark) {
			bookmark.favorite = bookmark.favorite || {};
			if (bookmark.favoriteSelected) {
				delete bookmark.favorite[$scope.userId];
			} else {
				bookmark.favorite[$scope.userId] = {
					created: moment().valueOf(),
				};
			}
			processShare(bookmark);
			shares.updateShareProperty(
				bookmark,
				'favorite',
				bookmark.favorite,
				callback
			);
			function callback(data) {}
		}

		function shareSuccess(shareData) {
			var translations = $translate.instant([
				'Bookmark created successfully',
			]);
			progressCallback(
				0,
				translations['Bookmark created successfully'],
				null,
				true,
				function () {
					var updatedShare = processShare(shareData);
					createShareDetail(updatedShare);
					$scope.share.list.push(updatedShare);
					$scope.edit.updating = true;

					$scope.edit.detailType = 'created';
					progressCallback(
						0,
						translations['Bookmark created successfully'],
						null,
						true,
						null,
						function () {
							$scope.edit.updating = false;
							applyFilter();
						}
					);
				}
			);
		}

		function shareDetails(share, $event) {
			if (!share || (!isShareEnabled() && share.shareWith !== 'self')) {
				$event.stopPropagation();
				return;
			}
			createShareDetail(share);
			$scope.edit.detailType = 'detail';
			$scope.share.secondPanel = true;
		}

		function updateShare(share, confirmed, callback) {
			var translations = $translate.instant([
				'Updating share',
				'Share updated successfully',
			]);
			$scope.edit.updating = true;
			progressCallback(0, translations['Updating share'], true, null);
			updateShareWith(true, () => {
				applyUpdate(confirmed);
			});

			function applyUpdate(confirmed) {
				if (!confirmed) {
					toggleConfirmUpdate();
				}
				shares.update(share.id, processUpdate, progressCallback);
			}

			function processUpdate(updatedShare, error) {
				if (error) {
					utilities.showModal(
						error.title || 'Unable to update share',
						error.content,
						error.cancelButtonText || 'ok',
						null,
						null,
						null,
						null,
						null,
						null,
						'#shares-manage'
					);
					progressCallback(
						0,
						translations['Unable to update share'],
						null,
						true,
						function () {
							$scope.edit.updating = false;
						}
					);
				} else {
					progressCallback(
						0,
						translations['Share updated successfully'],
						null,
						true,
						null,
						function () {
							updateURLDisplay(
								$scope.edit.share,
								$scope.edit.embedURL
							);

							if (callback) {
								callback();
							} else {
								$scope.edit.updating = false;
							}
						}
					);
				}
				updateURLDisplay($scope.edit.share, $scope.edit.embedURL);
			}
		}

		function removeShare(share) {
			$scope.edit.removing = true;
			toggleConfirmDelete();
			shares.remove(share.id, callback);

			function callback(result) {
				var index;

				for (var i = 0; i < $scope.share.list.length; i++) {
					if (share.id === $scope.share.list[i].id) {
						index = i;
						break;
					}
				}

				$scope.$evalAsync(function () {
					$scope.edit.removing = false;
					if (index !== undefined) {
						$scope.share.list.splice(index, 1);
					}
					if ($scope.share.create) {
						close();
					} else {
						$scope.share.secondPanel = null;
					}
					applyFilter();
				});
			}
		}

		function progressCallback(
			value,
			message,
			init,
			reset,
			onComplete,
			onAfterComplete
		) {
			var progressValue = Math.ceil(value * 100) || 1;
			if (reset) {
				$timeout(function () {
					if (onComplete) {
						$scope.share.progress = {};
						onComplete();
						return;
					}
					$scope.share.progress.show = true;
					$scope.share.progress.showComplete = true;
					$scope.share.progress.completeMessage = message;
					$scope.share.progress.complete = true;
					$scope.share.progress.started = false;
				}, 750);
				$timeout(function () {
					$scope.share.progress.showComplete = null;
				}, 3500);
				$timeout(function () {
					if (!$scope.share.progress.started) {
						$scope.share.progress = {};
						if (onAfterComplete) {
							onAfterComplete();
						}
					}
				}, 4000);
				return;
			}

			if (init) {
				if (!$scope.share.progress) {
					$scope.share.progress = {};
				} else {
					$scope.share.progress.showComplete = null;
					$scope.share.progress.complete = null;
				}
				$scope.share.progress.show = true;
				$scope.share.progress.started = true;
				$scope.share.progress.message = message;
			}

			$scope.$evalAsync(function () {
				$scope.share.progress.value = progressValue;
				$scope.share.progress.style = {width: progressValue + '%'};
				$scope.share.progress.showProgress = true;
			});
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}

		function previewShare($event, share) {
			if (!share || (!isShareEnabled && share.shareWith !== 'self')) {
				$event.stopPropagation();
				return;
			}
			var data = {
				toggleConfirmDelete: toggleConfirmDelete,
				close: close,
				shareDetails: shareDetails,
				share: share,
			};

			destroyPreview($event);

			share.toggleConfirmDelete = toggleConfirmDelete;
			share.close = close;
			share.shareDetails = shareDetails;
			$scope.targetShareID = share.id;

			preview = {
				id: 'previewShare',
				controller: 'SharesPreviewCtrl',
				container: $('body'),
				type: 'popover',
				target: $event.currentTarget,
				data: data,
				dataTitle: 'data',
				destroy: true,
				direction: 'left',
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				width: 190,
				anchorTop: true,
				useTargetOffset: true,
				show: true,
				class: 'popover-menu bookmark-preview',
			};

			previewElement = utilities.popover(
				preview,
				'<div ng-include="\'app/options/shares-preview.html\'"></div>'
			).element;
		}
		function destroyPreview($event) {
			var enteredPreview =
				$event.type === 'mouseleave' &&
				$event.relatedTarget &&
				previewElement &&
				previewElement[0] &&
				(previewElement[0] === $event.relatedTarget ||
					previewElement[0].contains($event.relatedTarget));
			if (preview && !enteredPreview) {
				preview.show = false;
			}
		}

		function isShareEnabled() {
			var config = seedcodeCalendar.get('config');
			var privilege = config.sharePrivileges;
			if (
				!config.preventSharing &&
				((privilege === 'admin' && config.admin) ||
					privilege === 'everyone')
			) {
				$scope.isShareEnabled = true;
				return true;
			} else {
				$scope.isShareEnabled = false;
				return false;
			}
		}

		function applyFilter() {
			if (!$scope.share?.list) {
				// We may try and update the filter from the input initializing before we have the share list
				// so ignore this if that's the case
				return;
			}
			const filter = seedcodeCalendar.get('config').bookmarkFilter;
			$scope.share.listFiltered = $scope.share.list.filter((bookmark) => {
				console.log(bookmark);
				return manageFilters.findTextInFields(filter, null, bookmark, {
					name: 'name',
					createdBy: 'createdBy',
					shareWithHuman: 'shareWithHuman',
				});
			});
		}

		function isPublic(shareWith) {
			return shares.isPublic(shareWith);
		}
	}

	function SharesPreviewCtrl($scope, shares, utilities, seedcodeCalendar) {
		$scope.confirmUpdateShare = confirmUpdateShare;
		$scope.manageShares = manageShares;
		$scope.isPublic = isPublic;

		//Set initial state if sharing and bookmarks are Allowed
		isShareEnabled();

		if (!$scope.data) {
			close();
		}

		$scope.share = $scope.data.share;

		function confirmUpdateShare(share) {
			const popover = {
				id: 'updateShare',
				controller: 'SharesCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 600,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: share,
				dataTitle: 'share',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};
			utilities.popover(
				popover,
				'<div ng-include="\'app/options/shares-update-dialog.html\'"></div>'
			);
		}

		function manageShares($event, share, update, visit, remove) {
			var popover;

			close();

			if (visit) {
				if ($scope.data.close) {
					$scope.data.close($event);
				}
				shares.update(
					share.id,
					function (updatedShare, error) {
						if (!updatedShare && error) {
							utilities.showModal(
								error.title || 'Unable to update share',
								error.content,
								error.cancelButtonText || 'ok'
							);
						} else {
							utilities.showModal(
								'Share updated successfully',
								error ? error.content : '',
								error ? error.cancelButtonText : 'ok'
							);
						}
					},
					null,
					true
				);
			} else if (remove) {
				if ($scope.data.toggleConfirmDelete) {
					$scope.data.toggleConfirmDelete();
				}
			} else if (update && share.id) {
				if (isPublic(share.shareWith)) {
					if ($scope.data.close) {
						$scope.data.close($event);
					}
					shares.update(share.id, function (updatedShare, error) {
						if (!updatedShare && error) {
							utilities.showModal(
								error.title || 'Unable to update share',
								error.content,
								error.cancelButtonText || 'ok'
							);
						} else {
							utilities.showModal(
								'Share updated successfully',
								error ? error.content : '',
								error ? error.cancelButtonText : 'ok'
							);
						}
					});
				}
			} else {
				if (share.shareDetails) {
					share.shareDetails(share);
				} else {
					popover = {
						id: 'manageShares',
						controller: 'SharesCtrl',
						container: $('body'),
						type: 'modal', // modal or popover
						width: 600,
						// positionX: e.pageX,
						// positionY: e.pageY,
						data: share,
						dataTitle: 'share',
						destroy: true,
						onShow: '',
						onShown: '',
						onHide: '',
						onHidden: '',
						show: true,
					};
					utilities.popover(
						popover,
						'<div ng-include="\'app/options/shares.html\'"></div>'
					);
				}
			}
		}

		function close(e) {
			//Stop propagation so we don't close popovers behind the modal when clicking close
			if (e) {
				e.stopPropagation();
			}
			$scope.popover.config.show = false;
		}

		function isShareEnabled() {
			var config = seedcodeCalendar.get('config');
			var privilege = config.sharePrivileges;
			if (
				!config.preventSharing &&
				((privilege === 'admin' && config.admin) ||
					privilege === 'everyone')
			) {
				$scope.isShareEnabled = true;
				return true;
			} else {
				$scope.isShareEnabled = false;
				return false;
			}
		}

		function isPublic(shareWith) {
			return shares.isPublic(shareWith);
		}
	}
})();
